import useAxios from '@/compositions/useAxios';

const { fetchData, postData } = useAxios();

export function requestLogin(payload) {
	// 로그인 요청
	return new Promise((resolve, reject) => {
		postData(
			'/signin',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function requestLogout() {
	// 로그아웃 요청
	return new Promise((resolve, reject) => {
		postData(
			'/logout',
			{},
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function requestSignup(payload) {
	// 회원가입 요청
	return new Promise((resolve, reject) => {
		postData(
			'/signup',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function saveSocialUser(payload) {
	// 소셜 회원가입 정보 저장
	return new Promise((resolve, reject) => {
		postData(
			'/saveSocialUser',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function requestToken(payload) {
	// 토큰 재발급
	return new Promise((resolve, reject) => {
		fetchData(
			'/requestToken',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function requestTSign(payload) {
	// 소셜 로그인을 위한 토큰 로그인
	return new Promise((resolve, reject) => {
		postData(
			'/tSignin',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function emailCheck(payload) {
	// 이메일 중복체크
	return new Promise((resolve, reject) => {
		fetchData(
			'/emailCheck',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				console.log(fail);
				reject(fail.message);
			},
		);
	});
}
export function sendCert(payload) {
	// 연락처 인증번호 전송
	return new Promise((resolve, reject) => {
		postData(
			'/sendCert',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function resendCert(payload) {
	// 연락처 인증번호 전송
	return new Promise((resolve, reject) => {
		postData(
			'/resendCert',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function resendCertFindIdPw(payload) {
	// 아이디, 비밀번호 찾기 - 연락처 인증번호 전송
	return new Promise((resolve, reject) => {
		postData(
			'/resendCertFindIdPw',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function findId(payload) {
	// 연락처 인증 완료 + 아이디 찾기
	return new Promise((resolve, reject) => {
		postData(
			'/findId',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function findPw(payload) {
	// 연락처 인증 완료 + 비밀번호 찾기
	return new Promise((resolve, reject) => {
		postData(
			'/findPw',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function saveCert(payload) {
	// 연락처 인증번호 전송
	return new Promise((resolve, reject) => {
		postData(
			'/saveCert',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function sendCertFindIdPw(payload) {
	// 아이디, 비밀번호 찾기 - 연락처 인증번호 전송
	return new Promise((resolve, reject) => {
		postData(
			'/sendCertFindIdPw',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function saveUserWithdraw(payload) {
	// 다시 이메일 중복체크 및 인증번호 발송
	return new Promise((resolve, reject) => {
		postData(
			'/saveUserWithdraw',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function changePw(payload) {
	// 비밀번호 변경
	return new Promise((resolve, reject) => {
		postData(
			'/changePw',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
export function changeContact(payload) {
	// 연락처 인증 완료 + 연락처 변경
	return new Promise((resolve, reject) => {
		postData(
			'/changeContact',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
