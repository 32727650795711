import { useStore } from 'vuex';

export const getSwiperDirection = () => {
	let initialX = null,
		initialY = null;

	const store = useStore();

	//처음 터치 시 스와이프 방향 초기화
	const initTouch = (e) => {
		store.commit('swipeDirection/initAllDirection');
		initialX = `${e.touches ? e.touches[0].clientX : e.clientX}`;
		initialY = `${e.touches ? e.touches[0].clientY : e.clientY}`;
	};

	//스와이프 방향(상,하,좌,우) 계산
	const swipeDirection = (e) => {
		if (initialX !== null && initialY !== null) {
			const currentX = `${e.touches ? e.touches[0].clientX : e.clientX}`,
				currentY = `${e.touches ? e.touches[0].clientY : e.clientY}`;

			let diffX = initialX - currentX,
				diffY = initialY - currentY;

			Math.abs(diffX) > Math.abs(diffY)
				? 0 < diffX
					? store.commit('swipeDirection/activeLeft')
					: store.commit('swipeDirection/activeRight')
				: 0 < diffY
				? store.commit('swipeDirection/activeUp')
				: store.commit('swipeDirection/activeDown');

			initialX = null;
			initialY = null;
		}
	};

	window.addEventListener('touchstart', initTouch);
	window.addEventListener('touchmove', swipeDirection);
};
