import { requestLogin, requestLogout } from '@/api/auth';
import { getUserInfo } from '@/api/user';
import router from '@/router';
import store from '..';

export default {
	namespaced: true,
	state: {
		isLogin: false,
		userInfo: {},
	},
	mutations: {
		setLogin(state, payload) {
			state.isLogin = payload;
		},
		setLogout(state) {
			state.isLogin = false;
		},
		setUserInfo(state, payload) {
			state.userInfo = payload;
		},
		resetData(state) {
			for (let obj in state) {
				state[obj] = '';
			}
		},
	},
	actions: {
		SET_USER_INFO({ commit }) {
			return new Promise((resolve, reject) => {
				getUserInfo()
					.then((data) => {
						commit('setUserInfo', data.user);
						resolve();
					})
					.catch((fail) => {
						router.push({ name: 'Home' });
						reject();
					});
			});
		},
		LOGIN({ commit, dispatch }, { email, password }) {
			return new Promise((resolve, reject) => {
				requestLogin({ email, password })
					.then(async ({ user }) => {
						const { access_token, refresh_token } = user;
						store.commit('token/setToken', { access_token, refresh_token });
						commit('setLogin', true);
						await dispatch('SET_USER_INFO');
						router.push({ name: 'Home' });
						resolve();
					})
					.catch((fail_msg) => {
						reject();
						//로그인 실패시 메시지 출력
						store.commit('toast/alert', fail_msg);
					});
			});
		},
		async LOGOUT({ commit }) {
			// 결과에 상관없이 무조건 로그아웃 시킴
			const res = await requestLogout();
			commit('setLogout');
			store.commit('token/clearToken');
			router.push({ name: 'Signin' });
		},
	},
};
