import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from '@/plugin/axios';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { numberonly, price, date, contact } from '@/directive/format';

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

const app = createApp(App);
app.directive('numberonly', numberonly);
app.directive('price', price);
app.directive('date', date);
app.directive('contact', contact);

app.config.globalProperties.axios = axios;
dayjs.locale('ko');
app.use(store).use(router).use(dayjs).mount('#app');
