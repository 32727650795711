export default {
	namespaced: true,
	state: {
		right: false,
		left: false,
		up: false,
		down: false,
		transitionName: 'none',
	},
	mutations: {
		setTransitionName(state, name) {
			state.transitionName = name;
		},
		activeRight(state) {
			state.right = true;
		},
		activeLeft(state) {
			state.left = true;
		},
		activeDown(state) {
			state.down = !state.down;
		},
		activeUp(state) {
			state.up = !state.up;
		},
		initAllDirection(state) {
			state.right = false;
			state.left = false;
			state.up = false;
			state.down = false;
		},
	},
};
