// 위에서 올라오는 bottom modal상태를 관리하는 파일
import { modalOpenScrollFix, modalCloseScrollRestore } from '@/compositions/useModalScrollControl';
import store from '..';

export default {
	namespaced: true,
	state: {
		CLOSE_DURATION: 200, // 200ms
        reviewBM: false,
		consultBM:false,

		//usageBS: false,
		//alertBS: false,
		// payBS: false,
		// payNowBS: false,
		// termsBS: false,
		// confirmBS: false,

		// selectBS 이 한 페이지에 2개 이상 포함되어 있으면 어떤게 열리고 닫히는지 구분하기 위한 변수
		// isClickGender: false,
		// isClickCal: false,
	},
	mutations: {
		openBM(state, bmName) {
			state[bmName] = true;
			modalOpenScrollFix();
		},
		closeBM(state) {
			for (let obj in state) {
				// 전체 overlay 중에서 열려있는 창 닫기
				if (state[obj]) {
					state[obj] = false;
				}
			}
			modalCloseScrollRestore();
		},
		closeBMAll(state) {
			for (const bmName in state) {
				store.commit('bm/closeBM', bmName);
			}
		},
	},

	actions: {},
	getters: {},
};
