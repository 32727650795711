export default {
	namespaced: true,
	state: {
		from: null,
		fromCoupon: null,
	},
	mutations: {
		//캐시 충전
		setFrom(state, payload) {
			state.from = payload;
		},
		initFrom(state) {
			state.from = null;
		},
		//쿠폰 구매
		setFromCoupon(state, payload) {
			state.fromCoupon = payload;
		},
		initFromCoupon(state) {
			state.fromCoupon = null;
		},
	},
};
