export default {
	namespaced: true,
	state: {
		access_token: '',
		refresh_token: '',
	},
	mutations: {
		setToken(state, { access_token, refresh_token }) {
			state.access_token = access_token;
			state.refresh_token = refresh_token;
		},
		clearToken(state) {
			state.access_token = '';
			state.refresh_token = '';
			localStorage.removeItem('token');
		},
	},
};
