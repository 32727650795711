export default {
	namespaced: true,
	state: {
		cash: null,
		cash_pk: null,
		data: null,
		depositData: null,
		paymentPrice: null,
	},
	mutations: {
		//캐시 충전 후 무통장입금 안내 데이터
		setDepositData(state, data) {
			state.depositData = data;
		},
		initDepositData(state) {
			state.depositData = null;
		},
		setCash(state, cash) {
			state.cash = cash;
		},
		setCashPk(state, cashPk) {
			state.cash_pk = cashPk;
		},
		setRefundDoneData(state, data) {
			state.data = data;
		},
		initRefund(state) {
			state.cash = null;
			state.cash_pk = null;
			state.data = null;
		},
	},
};
