import store from '@/store';
import { createRouter, createWebHistory } from 'vue-router';

// 로그인 여부 확인
const checkLogin = (to, from, next, alertContent = '로그인이 필요합니다.') => {
	const isLogin = store.state.user.isLogin;
	if (!isLogin) {
		store.commit('toast/alert', alertContent);
		router.push({ name: 'Signin' });
	}
};

// 이미 로그인했을 시 다시 들어가는거 방지
function loginGuard(next, alertContent = '이미 로그인/회원가입 하셨습니다.') {
	if (!store.state.user.isLogin) {
		next();
	} else {
		alert(alertContent);
		router.push({ name: 'Home' });
	}
}

// prettier-ignore
const routes = [
	{
		// 404 페이지
		path: '/404',
		name: '404',
		component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
	},
	{
		// 존재하지 않는 페이지 404로 리다이렉트
		path: '/:pathMatch(.*)*',
		redirect: '/404',
	},
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "404" */ '../views/Home.vue'),
	},
	//파운딩 보기
	{
		path: '/ViewFounding',
		name: 'ViewFounding',
		component: () => import(/* webpackChunkName: "404" */ '../views/Founding/ViewFounding.vue'),
	},
	//파운딩 상세
	{
		path: '/FoundingDetail/:foundingId',
		name: 'FoundingDetail',
		component: () => import(/* webpackChunkName: "404" */ '../views/Founding/FoundingDetail.vue'),
	},
	//캐시후원
	{
		path: '/CashSupport/:foundingId',
		name: 'CashSupport',
		component: () => import(/* webpackChunkName: "404" */ '../views/Founding/CashSupport.vue'),
		//파운더는 이전페이지로, 비회원은 로그인 페이지로 이동
		beforeEnter: (to, from, next) =>{
			checkLogin();
			if(store.state.user.userInfo.division === 'founder') {
				store.commit('toast/alert', '파운더는 사용할 수 없는 기능입니다.');
			} else {
				next();
			}
		}
	},
	//캐시충전
	{
		path: '/ChargeCash',
		name: 'ChargeCash',
		component: () => import(/* webpackChunkName: "404" */ '../views/Cash/ChargeCash.vue'),
		beforeEnter: (to, from, next) =>{
			checkLogin();
			next();
		}
	},
	//캐시충전 완료
	{
		path: '/ChargeCashDone',
		name: 'ChargeCashDone',
		component: () => import(/* webpackChunkName: "404" */ '../views/Cash/ChargeCashDone.vue'),
		//캐시충전으로부터 오지 않았다면 접근 막음
		beforeEnter: (to, from, next) => {
			if(from.name === 'ChargeCash'){				
				next()
			}
		}
	},
	//사용자 검색
	{
		path: '/UserSearch/:filter',
		name: 'UserSearch',
		component: () => import(/* webpackChunkName: "404" */ '../views/User/UserSearch.vue'),
	},
	//투자 요청
	{
		path: '/InvestRequest',
		name: 'InvestRequest',
		component: () => import(/* webpackChunkName: "404" */ '../views/User/InvestRequest.vue'),
		beforeEnter: (to, from, next) => {
			store.commit('toast/alert', '현재 사용할 수 없는 기능입니다.');
		}
		
	},
	//투자 요청
	{
		path: '/User/SendInvestRequest',
		name: 'User/SendInvestRequest',
		component: () => import(/* webpackChunkName: "404" */ '../views/User/SendInvestRequest.vue'),
		beforeEnter: (to, from, next) => {
			store.commit('toast/alert', '현재 사용할 수 없는 기능입니다.');
		}
	},
	//사용자 프로필
	{
		path: '/UserProfile/:user_pk',
		name: 'UserProfile',
		component: () => import(/* webpackChunkName: "404" */ '../views/User/UserProfile.vue'),
	},
	//프로필-부스터프로-컨설팅상담
	{
		path: '/User/ConsultingCs/:user_pk',
		name: 'User/ConsultingCs',
		component: () => import(/* webpackChunkName: "404" */ '../views/User/ConsultingCs.vue'),
		beforeEnter: (to, from, next) => {
			
			checkLogin();
			if( store.state.user.userInfo.division === 'booster'||  store.state.user.userInfo.division === 'boosterPro') {
				//부스터, 부스터프로는 사용 못하는 기능
				store.commit('toast/alert', '부스터, 부스터 프로는 사용할 수 없는 기능입니다.');
			} else {
				next();
			}
		}
	},
	//프로필-부스터프로-컨설팅상담 완료
	{
		path: '/User/ConsultingCsDone/:user_pk',
		name: 'User/ConsultingCsDone',
		component: () => import(/* webpackChunkName: "404" */ '../views/User/ConsultingCsDone.vue'),
		beforeEnter:(to, from, next) => {
			if(from.name === 'User/ConsultingCs') {
				next()
			}
		}
	},
	//쿠폰 구매
	{
		path: '/PayCoupon',
		name: 'PayCoupon',
		component: () => import(/* webpackChunkName: "404" */ '../views/User/Coupon/PayCoupon.vue'),
		beforeEnter: (to, from, next) =>{
			checkLogin();
			next();
		}
	},
	//쿠폰 구매 완료
	{
		path: '/PayCouponDone',
		name: 'PayCouponDone',
		component: () => import(/* webpackChunkName: "404" */ '../views/User/Coupon/PayCouponDone.vue'),
	},
	//마이페이지 메인
	{
		path: '/Mypage',
		name: 'Mypage',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMain.vue'),
		beforeEnter: (to, from, next) => {
			//비회원일 시 로그인으로 이동
			checkLogin(to, from, next);
			next();
		}
	},
	//팔로워 리스트
	{
		path: '/Mypage/FollowersList',
		name: 'Mypage/FollowersList',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/Followers.vue'),
		beforeEnter: (to, from, next) =>{
			checkLogin();
			next();
		}
	},
	//팔로잉 리스트
	{
		path: '/Mypage/FollowingsList',
		name: 'Mypage/FollowingsList',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/Followings.vue'),
		beforeEnter: (to, from, next) =>{
			checkLogin();
			next();
		}
	},
	//프로필 관리
	{
		path: '/Mypage/ManageProfile',
		name: 'Mypage/ManageProfile',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/ManageProfile.vue'),
		beforeEnter: (to, from, next) =>{
			checkLogin();
			next();
		}
	},
	//개인정보 수정
	{
		path: '/Mypage/ModifyInfo',
		name: 'Mypage/ModifyInfo',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/ModifyInfo.vue'),
		beforeEnter: (to, from, next) =>{
			checkLogin();
			next();
		}
	},
	//성장일지
	{
		path: '/Mypage/GrowthDiary',
		name: 'Mypage/GrowthDiary',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/GrowthDiary.vue'),
		beforeEnter: (to, from, next) =>{
			checkLogin();
			if(store.state.user.userInfo.division === 'founder'){
				next();
			}else {
				store.commit('toast/alert', '잘못된 접근입니다.')
				router.push(from);
			}
		
		}
	},
	//캐시내역
	{
		path: '/Mypage/CashList',
		name: 'Mypage/CashList',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/CashList.vue'),
		beforeEnter: (to, from, next) =>{
			checkLogin();
			next();
		}
	},
	//환불요청
	{
		path: '/RefundRequest',
		name: 'RefundRequest',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Refund/RefundRequest.vue'),
	},
	//환불요청 완료
	{
		path: '/RefundDone',
		name: 'RefundDone',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Refund/RefundDone.vue'),
	},
	//파운딩관리
	{
		path: '/Mypage/ManageFounding',
		name: 'Mypage/ManageFounding',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Founding/ManageFounding.vue'),
	},
	//파운딩등록
	{
		path: '/Mypage/RegisterFounding',
		name: 'Mypage/RegisterFounding',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Founding/RegisterFounding.vue'),
	},
	//파운딩수정
	{
		path: '/Mypage/ModifyFounding/:founding_pk',
		name: 'Mypage/ModifyFounding',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Founding/ModifyFounding.vue'),
	},
	//부스터관리
	{
		path: '/Mypage/ManageBooster',
		name: 'Mypage/ManageBooster',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Booster/ManageBooster.vue'),
	},
	//응원내역
	{
		path: '/Mypage/CheerList/:foundingPk',
		name: 'Mypage/CheerList',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Booster/CheerList.vue'),
	},
	//부스팅내역
	{
		path: '/Mypage/BoostingList/:foundingPk',
		name: 'Mypage/BoostingList',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Booster/BoostingList.vue'),
	},
	//컨설팅 상담
	{
		path: '/Mypage/Consulting',
		name: 'Mypage/Consulting',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Consulting/Consulting.vue'),
	},
	//상담내역
	{
		path: '/Mypage/ConsultingList/:consulting_counsel_pk',
		name: 'Mypage/ConsultingList',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Consulting/ConsultingList.vue'),
	},
	//파운더관리
	{
		path: '/Mypage/ManageFounder',
		name: 'Mypage/ManageFounder',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/ManageFounder.vue'),
	},
	//투자 상담
	{
		path: '/Mypage/InvestConsult',
		name: 'Mypage/InvestConsult',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Invest/InvestConsult.vue'),
		beforeEnter: (to, from, next) => {
			store.commit('toast/alert', '현재 사용할 수 없는 기능입니다.')
		}
	},
	//투자요청 템플릿
	{
		path: '/Mypage/InvestReqTmpl',
		name: 'Mypage/InvestReqTmpl',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Invest/InvestReqTmpl.vue'),
	},
	//요청내역
	{
		path: '/Mypage/RequestList',
		name: 'Mypage/RequestList',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Invest/RequestList.vue'),
	},
	//로그인
	{
		path: '/Signin',
		name: 'Signin',
		component: () => import(/* webpackChunkName: "404" */ '../views/Auth/Signin.vue'),
		beforeEnter: (to, from, next)=>{
			loginGuard(next);
		}
	},
	//아이디 비번 찾기
	{
		path: '/FindIdPw',
		name: 'FindIdPw',
		component: () => import(/* webpackChunkName: "404" */ '../views/Auth/FindIdPw.vue'),
	},
	//회원가입 계정 선택
	{
		path: '/SignupOption',
		name: 'SignupOption',
		component: () => import(/* webpackChunkName: "404" */ '../views/Auth/SignupOption.vue'),
	},
	//회원가입 계정 선택(카카오)
	{
		path: '/SignupOption/kakao',
		name: 'SignupOptionKakao',
		component: () => import(/* webpackChunkName: "404" */ '../views/Auth/SignupOptionKakao.vue'),
	},
	//회원가입 step1
	{
		path: '/SignupStep1/:registerType/:joinPath',
		name: 'SignupStep1',
		component: () => import(/* webpackChunkName: "404" */ '../views/Auth/SignupStep1.vue'),
	},
	//회원가입 step2
	{
		path: '/SignupStep2/:registerType/:joinPath',
		name: 'SignupStep2',
		component: () => import(/* webpackChunkName: "404" */ '../views/Auth/SignupStep2.vue'),
	},
	//회원가입 완료
	{
		path: '/SignupDone/:registerType',
		name: 'SignupDone',
		component: () => import(/* webpackChunkName: "404" */ '../views/Auth/SignupDone.vue'),
	},
	//회원탈퇴
	{
		path: '/Unregister',
		name: 'Unregister',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Unregister/Unregister.vue'),
	},
	//회원탈퇴 완료
	{
		path: '/UnregisterDone',
		name: 'UnregisterDone',
		component: () => import(/* webpackChunkName: "404" */ '../views/Mypage/MypageMenu/Unregister/UnregisterDone.vue'),
		beforeEnter: (to, from, next) => {
			//비회원일 시 로그인으로 이동
			checkLogin(to, from, next);
			next();
		}
	},
	
	
];

const router = createRouter({
	mode: 'history',
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		//뒤로 가기, 앞으로 가기(popstate 이벤트 발생)시 스크롤 위치 유지
		// if (savedPosition) {
		// 	return new Promise((resolve, reject) => {
		// 		//트랜지션 및 렌더링 이후 스크롤을 옮겨야 하므로 스크롤 지연
		// 		setTimeout(() => {
		// 			resolve(savedPosition);
		// 		}, 2000);
		// 	});
		// } else {
		// 	// return new Promise((resolve, reject) => {
		// 	// 	//트랜지션 및 렌더링 이후 스크롤을 옮겨야 하므로 스크롤 지연
		// 	// 	setTimeout(() => {
		// 	// 		resolve({ top: 0 });
		// 	// 	}, 150);
		// 	// });
		// 	return { top: 0 };
		// }
		return { top: 0 };
	},
});

export default router;
