export default {
	namespaced: true,
	state: {
		isBack: false,
	},
	mutations: {
		activateBack(state) {
			state.isBack = true;
		},
		inactivateBack(state) {
			state.isBack = false;
		},
	},
};
