// vue 내장 라이브러리
import router from '@/router';

export default {
	namespaced: true,
	state: {
		isAuth: false,
		//Step 1
		email: '',
		password: '',
		name: '',
		phoneNum: '',
		agreeTerms: '',
		agreePrivateInfo: '',
		agreeSMS: '',
		//Step 2 - 공통
		profileImg: '',
		gender: '',
		age: '',
		introContent: '',
		businessType: ['asd', 'asd'],
		interestServiceItem: [],
		//Step 2 - Founder
		company: '',
		website: '',
		businessPeriod: '',
		founderSignupGoal: [],
		//Step 2 - Booster & Booster Pro
		carrer: '',
		job: '',
		certImg: '',
		boosterSignupGoal: [],
		availableConsulting: [],
		supportMethod: [],
		supportScale: [],

		intervalId: 0, // 휴대폰 인증 페이지에서 3분 타이머의 intervalId가 겹치지 않기 위해서 관리
	},
	mutations: {
		setIsAuth(state, payload) {
			state.isAuth = payload;
		},
		// Step 1
		setEmail(state, payload) {
			state.email = payload;
		},
		setPassword(state, payload) {
			state.password = payload;
		},
		setName(state, payload) {
			state.name = payload;
		},
		setPhoneNum(state, payload) {
			state.phoneNum = payload;
		},
		setAgreeTerms(state, payload) {
			state.agreeTerms = payload;
		},
		setAgreePrivateInfo(state, payload) {
			state.agreePrivateInfo = payload;
		},
		setAgreeSMS(state, payload) {
			state.agreeSMS = payload;
		},
		// Step 2
		setProfileImg(state, payload) {
			state.profileImg = payload;
		},
		setGender(state, payload) {
			state.gender = payload;
		},
		setAge(state, payload) {
			state.age = payload;
		},
		setIntroContent(state, payload) {
			state.introContent = payload;
		},
		setCompany(state, payload) {
			state.company = payload;
		},
		setWebsite(state, payload) {
			state.website = payload;
		},
		setBusinessPeriod(state, payload) {
			state.businessPeriod = payload;
		},
		setCarrer(state, payload) {
			state.carrer = payload;
		},
		setJob(state, payload) {
			state.job = payload;
		},
		setCertImg(state, payload) {
			state.certImg = payload;
		},
		setBusinessType(state, payload) {
			state.businessType.push(payload);
		},
		setInterestServiceItem(state, payload) {
			state.interestServiceItem.push(payload);
		},
		setFounderSignupGoal(state, payload) {
			state.founderSignupGoal.push(payload);
		},
		setBoosterSignupGoal(state, payload) {
			state.BoosterSignupGoal.push(payload);
		},
		setAvailabeConsulting(state, payload) {
			state.availableConsulting.push(payload);
		},
		setSupportMethod(state, payload) {
			state.supportMethod.push(payload);
		},
		setSupportScale(state, payload) {
			state.supportScale.push(payload);
		},
		// Step 3

		setProperty(state, payload) {
			const { property, value } = payload;
			state[property] = value;
		},
		// 저장된 데이터 리셋함. 회원가입 등록을 완료하거나 프로필 수정 시 수행.
		resetData(state) {
			for (let obj in state) {
				if (typeof state[obj] === 'object') {
					state[obj] = [];
				} else {
					state[obj] = '';
				}
			}
		},
	},
	actions: {
		// async SAVE_OBITUARY(context) {
		// 	var hostRs = "";
		// 	const host = context.state.host;
		// 	host.forEach(host => hostRs += host.relation + "|" + host.hostName + "!!");
		// 	const formData = new FormData();
		// 	formData.append('name', context.state.name);
		// 	formData.append('age', context.state.age);
		// 	formData.append('gender', context.state.gender);
		// 	formData.append('awayDate', context.state.awayDate);
		// 	formData.append('file', context.state.profileImage);
		// 	saveEvent(formData).then((res) => {
		// 		console.log(res);
		// 		router.push('/manage');
		// 	})
		// },
	},
};
