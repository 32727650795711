import useAxios from '@/compositions/useAxios';

const { fetchData, postData } = useAxios();

export function getUserInfo(params) {
	// 회원정보 조회
	return new Promise((resolve, reject) => {
		fetchData(
			'/getUserInfo',
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}

export function saveUserInfo(params) {
	// 회원정보 수정
	return new Promise((resolve, reject) => {
		postData(
			'/saveUser',
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}

export function getSurvey(params) {
	// Survey 목록 조회
	return new Promise((resolve, reject) => {
		fetchData(
			'/getSurvey',
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}

export function getUsers(params) {
	// 모든 사용자 정보 조회
	return new Promise((resolve, reject) => {
		fetchData(
			'/getUsers',
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}

export function getUserProfile(params) {
	// 사용자 프로필 조회
	return new Promise((resolve, reject) => {
		fetchData(
			`/getUserProfile`,
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}

export function saveUserProfile(params) {
	// 사용자 프로필 조회
	return new Promise((resolve, reject) => {
		postData(
			`/saveUserProfile`,
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}

export function savefollow(params) {
	// 팔로우
	return new Promise((resolve, reject) => {
		postData(
			`/savefollow`,
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}

export function getMyPage(params) {
	// 사용자 프로필 조회
	return new Promise((resolve, reject) => {
		fetchData(
			`/getMypage`,
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}

export function getMyFollowers(params) {
	// 나를 팔로워하는 유저 목록 조회
	return new Promise((resolve, reject) => {
		fetchData(
			`/getMyFollowers`,
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}

export function getMyFollowings(params) {
	// 내가 팔로잉하는 유저 목록 조회
	return new Promise((resolve, reject) => {
		fetchData(
			`/getMyFollowings`,
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}
