export default {
	namespaced: true,
	state: {
		toastContent: '', // 알림창 내용
	},
	mutations: {
		// 알림창
		alert(state, payload) {
			// 1.5초동안 보여준 뒤, 1초에 걸쳐서 사라짐
			if (state.toastContent) {
				// 토스트 사라지기 전에 클릭 여러번하면 timeout 고장나는거 방지
				return;
			}
			state.toastContent = payload;
			const toId = setTimeout(() => {
				document.querySelector('.toast')?.classList.add('fade_out');
				const totoId = setTimeout(() => {
					state.toastContent = '';
					clearTimeout(totoId);
				}, 1000);
				clearTimeout(toId);
			}, 1500);
		},
	},
};
