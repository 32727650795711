import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import header from './modules/header';
import modal from './modules/modal';
import token from './modules/token';
import signup from './modules/signup';
import user from './modules/user';
import toast from './modules/toast';
import bm from './modules/bottomModal';
import time from './modules/time';
import swipeDirection from './modules/swipeDirection';
import refund from './modules/refund';
import fromUrl from './modules/fromUrl';
import coupon from './modules/coupon';
import axiosLoading from './modules/axiosLoading';

export default createStore({
	state: {},
	mutations: {
		setScrollInfo(state, { scrollDirection }) {
			if (scrollDirection !== undefined) state.scrollDirection = scrollDirection;
		},
	},
	actions: {},
	modules: {
		header,
		modal,
		token,
		signup,
		user,
		toast,
		bm,
		time,
		swipeDirection,
		refund,
		fromUrl,
		coupon,
		axiosLoading,
	},
	plugins: [
		createPersistedState({
			paths: ['user', 'event'],
		}),
		createPersistedState({
			key: 'token',
			paths: ['token'],
		}),
	],
});
