export default {
	namespaced: true,
	state: {
		payCouponData: null,
	},
	mutations: {
		setPayCouponData(state, payload) {
			state.payCouponData = payload;
		},
		initPayCouponData(state) {
			state.payCouponData = null;
		},
	},
};
