<template>
	<div class="bottom_menu">
		<ul class="c_flex_wrap">
			<li :class="$route.name === 'Home' ? 'off on' : 'off'">
				<router-link to="/">HOME</router-link>
			</li>
			<li :class="$route.name === 'ViewFounding' ? 'off on' : 'off'">
				<router-link to="/ViewFounding">파운딩보기</router-link>
			</li>
			<li :class="$route.name === 'UserSearch' ? 'off on' : 'off'">
				<router-link :to="{ name: 'UserSearch', params: { filter: '전체' } }">사용자검색</router-link>
			</li>
			<li :class="$route.name === 'Mypage' ? 'off on' : 'off'">
				<router-link to="/Mypage">마이페이지</router-link>
			</li>
		</ul>
	</div>
</template>

<script setup>
	//vue 내장 라이브러리
	import { computed } from 'vue';
	import { useStore } from 'vuex';
	import { useRoute } from 'vue-router';

	const store = useStore();
	const toggleGnb = (action = 'toggle') => {
		if (action === 'toggle') {
			store.commit('header/toggle');
		} else if (action === 'open') {
			store.commit('header/open');
		}
	};
	const route = useRoute();
	const menu = computed(() => {
		return route.name;
	});
</script>

<style scoped src="@/assets/css/components/bottom_menu_bar.css"></style>
