<template>
	<ToastAlert />

	<router-view v-slot="{ Component }">
		<!-- <component :is="Component" :key="route.fullPath" /> -->
		<transition :name="store.state.swipeDirection.transitionName">
			<component :is="Component" :key="route.fullPath" />
		</transition>
	</router-view>

	<BottomMenuBar v-show="botMenuList.includes(currentPage)" />
</template>

<script setup>
	// 컴포넌트
	import BottomMenuBar from './components/common/BottomMenuBar.vue';
	import ToastAlert from './components/ToastAlert.vue';
	//util
	import { getSwiperDirection } from '@/compositions/getSwipeDirection';
	// vue 내장 라이브러리
	import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
	import { useStore } from 'vuex';
	import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
	import { useDeviceInfo } from './compositions/useDeviceInfo';

	const keepAliveList = [];
	const botMenuList = ['Home', 'ViewFounding', 'UserSearch', 'Mypage']; // botMenuList에 있는 url에서만 바텀메뉴바를 노출해야함
	const route = useRoute();
	const store = useStore();
	//useSlide 변수
	let lastHistoryPosition = ref(window.history.state.position);
	const noTransitionPage = [...botMenuList];
	const currentPage = computed(() => {
		return route.name;
	});

	//브라우저의 url바에 의해 요소가 가려지는 문제 해결
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);

	window.addEventListener('resize', () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	});
	const setScreenSize = () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	};
	setScreenSize();

	//슬라이드 트랜지션 제어
	const useSlide = () => {
		watch(
			() => route.name,
			(to, from) => {
				const currentHistoryPosition = window.history.state.position;
				store.commit('swipeDirection/setTransitionName', 'none');
				const { deviceInfo } = useDeviceInfo();

				// 바텀메뉴 -> 바텀메뉴간 이동 시 효과없음
				if (noTransitionPage.includes(to) && noTransitionPage.includes(from)) {
					lastHistoryPosition.value = currentHistoryPosition;
					return;
				}

				//Android
				if (deviceInfo.isAnd) {
					//앱 내의 뒤로 가기 버튼을 눌렀을 때
					if (store.state.header.isBack) {
						store.commit('swipeDirection/setTransitionName', 'slide-left');
						store.commit('header/inactivateBack');
						lastHistoryPosition.value = currentHistoryPosition;

						return;
					} //앱 내에서 클릭으로 다음 페이지 이동할 때
					if (currentHistoryPosition > lastHistoryPosition.value) {
						store.commit('swipeDirection/setTransitionName', 'slide-right');
						lastHistoryPosition.value = currentHistoryPosition;
						return;
					} //디바이스 자체 뒤로 가기 버튼을 눌렀을 때
					if (currentHistoryPosition < lastHistoryPosition.value) {
						store.commit('swipeDirection/setTransitionName', 'slide-left');
						lastHistoryPosition.value = currentHistoryPosition;
						return;
					}
				}
				//IOS
				else if (deviceInfo.isIos) {
					//사파리 브라우저의 앞으로 가기 SWIPE할 때
					if (currentHistoryPosition > lastHistoryPosition.value && store.state.swipeDirection.left) {
						store.commit('swipeDirection/setTransitionName', 'none');
						lastHistoryPosition.value = currentHistoryPosition;
						return;
					}
					//사파리 브라우저의 뒤로 가기 SWIPE할 때
					if (currentHistoryPosition < lastHistoryPosition.value && store.state.swipeDirection.right) {
						store.commit('swipeDirection/setTransitionName', 'none');
						lastHistoryPosition.value = currentHistoryPosition;
						return;
					}
					//앱 내의 뒤로 가기 버튼을 눌렀을 때
					if (store.state.header.isBack || currentHistoryPosition < lastHistoryPosition.value) {
						store.commit('swipeDirection/setTransitionName', 'slide-left');
						store.commit('header/inactivateBack');
						lastHistoryPosition.value = currentHistoryPosition;
						return;
					} //앱 내에서 클릭으로 다음 페이지 이동할 때
					if (currentHistoryPosition > lastHistoryPosition.value) {
						store.commit('swipeDirection/setTransitionName', 'slide-right');
						lastHistoryPosition.value = currentHistoryPosition;
						return;
					}
				}
				lastHistoryPosition.value = currentHistoryPosition;
			},
		);
	};

	//모바일의 스와이프 모션을 감지하여 스와이프 방향을 알아냄
	getSwiperDirection();
	useSlide();
</script>

<style>
	@import url('./assets/css/reset.css');
	@import url('./assets/css/common.css');
	@import url('./assets/css/layout.css');
	@import url('./assets/css/transition.css');
</style>
