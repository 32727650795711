export const useDeviceInfo = () => {
	const userAgent = navigator.userAgent.toLocaleLowerCase();
	const deviceInfo = {
		isMobile: false,
		isAnd: false,
		isIos: false,
	};

	if (userAgent.indexOf('android') > -1) {
		deviceInfo.isMobile = true;
		deviceInfo.isAnd = true;
	} else if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1 || userAgent.indexOf('ipod') > -1) {
		deviceInfo.isMobile = true;
		deviceInfo.isIos = true;
	}

	return {
		deviceInfo,
	};
};
