import { modalOpenScrollFix, modalCloseScrollRestore } from '@/compositions/useModalScrollControl';
import store from '..';

export default {
	namespaced: true,
	state: {
		reviewModal: false,
		reviewReplyModal: false,
		makeGrowthModal: false,
		modifyGrowthModal: false,
		modifyGrowthData: { growth_pk: 0, price: 0, content: '' },
		followingModal: false,
		followersModal: false,
		usingTermsModal: false,
		privateTermsModal: false,
		// addressOverlay: false,
		// bankListOverlay: false,
		// attendListOverlay: false,
		// usageFilterOverlay: false,
		// payPasswordOverlay: false,
		// phoneCertOverlay: false,
		// bioAuthOverlay: false,
	},
	mutations: {
		openModal(state, modalName) {
			state[modalName] = true;
			modalOpenScrollFix();
		},
		closeModal(state, modalName) {
			modalCloseScrollRestore();
			const modalEl = document.querySelector('#desktop-modal .modal .modal-content .c_contents_container');
			if (modalEl) {
				modalEl.classList.add('hide');
				const toId = setTimeout(() => {
					state[modalName] = false;
					clearTimeout(toId);
				}, store.state.time.TRANSITION_SPEED + 50);
			}
		},
		closeModalAll(state) {
			for (const modalName in state) {
				store.commit('modal/closeModal', modalName);
			}
		},
		setmodifyGrowthData(state, payload) {
			state.modifyGrowthData = payload;
		},
		closeFollowing(state) {
			state.followingModal = false;
			modalCloseScrollRestore();
		},

		closeFollow(state) {
			state.followersModal = false;
			modalCloseScrollRestore();
		},
		closeMakeGrowth(state) {
			state.makeGrowthModal = false;
			modalCloseScrollRestore();
		},
		closeModifyGrowth(state) {
			state.modifyGrowthModal = false;
			modalCloseScrollRestore();
		},
		closeReviewModal(state) {
			state.reviewModal = false;
			modalCloseScrollRestore();
		},
		closeReviewReplyModal(state) {
			state.reviewReplyModal = false;
			modalCloseScrollRestore();
		},
		closeUsingTermsModal(state) {
			state.usingTermsModal = false;
			modalCloseScrollRestore();
		},
		closePrivateTermsModal(state) {
			state.privateTermsModal = false;
			modalCloseScrollRestore();
		},
	},
	actions: {},
	getters: {},
};
