export default {
	namespaced: true,
	state: {
		isLoading: false,
		url: '',
	},
	mutations: {
		startLoading(state) {
			state.isLoading = true;
		},
		finishLoading(state) {
			state.isLoading = false;
		},
		setUrl(state, payload) {
			state.url = payload;
		},
	},
};
