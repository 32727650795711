import store from '@/store';
import axios from 'axios';
import router from '@/router';

// axios.defaults.baseURL = 'https://admin.withfounder.co.kr/api'; // for deploy
axios.defaults.baseURL = 'https://admin.withfounder.com/api'; // for develop

//request 전처리
axios.interceptors.request.use((request) => {
	//로딩 중이면서 요청 url이 같으면 request 무시
	if (request.url === store.state.axiosLoading.url && store.state.axiosLoading.isLoading) {
		return;
	} else {
		store.commit('axiosLoading/setUrl', request.url);
		store.commit('axiosLoading/startLoading');
		return request;
	}
});

//response 전처리
axios.interceptors.response.use(
	(response) => {
		// interceptor를 통해 백엔드 API로부터 받은 값을 먼저 처리함
		// 2xx 범위에 있는 상태코드는 여기에서 실행됨
		if (response.data.code == 99104 || response.data.code == 99105) {
			store.commit('toast/alert', '로그인이 만료되었습니다. 다시 로그인해주세요.');
			store.commit('token/clearToken');
			store.commit('user/setLogout');
			router.push({ name: 'Signin' });
		}
		//로딩 상태, 요청 url 초기화
		setTimeout(() => {
			store.commit('axiosLoading/finishLoading');
			store.commit('axiosLoading/setUrl', '');
		}, 400);

		return response;
	},
	(error) => {
		// 2xx 외의 범위에 있는 상태코드는 여기에서 실행됨
		//console.log(error);
		return error;
	},
);

export default axios;
